import React from "react";
import {graphql} from "gatsby";
import {Helmet} from "react-helmet";
import Layout from "../components/layout";
import {componentMap, DataProvider} from "../contentful/data-provider";
import ogimage from "../images/ogimages/ecosystem.png";

export default function EcosystemPage({data}) {
    const dataProvider = new DataProvider(data);
    const categories = data.allContentfulBlogPostCategory?.nodes
    return (
        <Layout>
            <Helmet
                meta={[
                    {
                        property: `og:title`,
                        content: 'Agoric - Ecosystem',
                    },
                    {
                        property: `twitter:title`,
                        content: 'Agoric - Ecosystem',
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },
                    {
                        property: `twitter:image`,
                        content: ogimage,
                    },
                    {
                        property: `og:image`,
                        content: ogimage,
                    },
                    {
                        property: `twitter:card`,
                        content: `summary_large_image`,
                    },
                ]}
            >
                <title>Agoric - Ecosystem</title>
            </Helmet>
            <div className={'ecosystem-page'}>
                { dataProvider.getCurrentPageSections().map((section, index) => {
                    return (
                        <>
                            {React.createElement(
                                componentMap[section.type],
                                {
                                    content: dataProvider.getElementById(section.id),
                                    categories,
                                    activeCategory: 'all',
                                    index
                                }
                            )}
                        </>
                    );
                }) }
            </div>
        </Layout>
    );
}

export const query = graphql`
    query {
        contentfulPage(id: {eq: "7b167048-b5c2-5606-9dd6-834511bddb11"}) {
            ... CurrentPageDetails
        }
        allContentfulEntry {
            edges {
                node {
                    ... AllEntryDetails
                }
            }
        }
        allContentfulBlogPostCategory(filter: {slug: {ne: "all"}, isEconomyCategory: {eq: true}}) {
            nodes {
                id
                title
                slug
                isEconomyCategory
            }
        }
    }
`;
